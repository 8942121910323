<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center mb-4">
                    <div class="text-center">
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}
							<!-- <br>{{desc3}} -->
						</p>
                    </div>
                </div>
			</div>
			<div class="col-lg-12">
				<div class="d-flex justify-content-center" style="height:150%">
					<input ref="cursor" type="text" class="form-control w-25 h-100 text-center rounded-pill" placeholder="검색키워드" v-model="targetKeyword" @keyup.enter="searchenter($event)">
				</div>
			</div>
			<div class="col-lg-12 mt-4 mb-5">
				<div class="d-flex justify-content-center align-items-center">
					<button type="button" class="btn btn-primary" @click="search">키워드 분석</button>
					<!-- <div class="custom-control custom-switch custom-control-inline ml-3">
						<input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="related">
						<label class="custom-control-label" for="customSwitch1">연관검색어</label>
					</div> -->
				</div>
			</div>
			<div class="col-lg-12 mt-5" v-if="showspinner">
				<div class="custom-control custom-control-inline d-flex justify-content-center">
					<div class="row">
						<div class="col-12">
							<img style="max-width:50px;" :src="require('../../../assets/images/small/loading.gif')">
							<span>{{loadingtext}}... {{loadingrate}}</span>
						</div>
					</div>
				</div>
			</div>
			<card class="col-lg-12" v-if="showlist">
				<div class="p-3">
					<div class="table-responsive text-center rounded mb-3" style="font-size:80%">
						<tableList>
							<template v-slot:header>
								<tr class="ligth">
									<th>키워드</th>
									<th style="min-width:200px;">상품정보</th>
									<!-- <th style="font-size:90%;">카테고리</th> -->
									<th>판매가</th>
									<th>배송비</th>
									<!-- <th>등록일</th> -->
									<th>리뷰수</th>
									<th>리뷰평점</th>
									<!-- <th>월판매수량ST</th>
									<th>월매출ST</th> -->
									<th>월판매건수</th>
									<th>월매출</th>
									<th>7일판매건수</th>
									<!-- <th>6개월판매건수</th> -->
									<th class="border-right">Actions</th>
								</tr>
							</template>
							<template v-slot:body>
								<tr v-for="a,i in rowlines" :key="i">
									<td>{{a.keyword}}</td>
									<td style="font-size:100%;min-width:200px;">
										<a :href="a.상품링크" target="_blank">
										<div class="d-flex align-items-center">
											<img :src="a.상품이미지" class="img-fluid rounded avatar-100 mr-3" alt="image">
											<div>
												<p class="mb-0"><small>{{a.상품명}}</small></p>
											</div>
										</div>
										</a>
									</td>
									<!-- <td>{{a.카테고리}}</td> -->
									<td>{{a.판매가}}</td>
									<td>{{a.배송비}}</td>
									<!-- <td>{{a.등록일}}</td> -->
									<td>{{a.리뷰수}}</td>
									<td>{{a.평점}}</td>
									<!-- <td>{{a.월판매수ST ? a.월판매수ST.toFixed(0) : 0}}</td>
									<td>{{a.월매출ST ? a.월매출ST.toFixed(0) : 0}}</td> -->
									<td>{{a.월판매수 ? a.월판매수 : 0}}</td>
									<td>{{a.월매출 ? a.월매출 : 0}}</td>
									<td>{{a['7일판매건수']}}</td>
									<!-- <td>{{a['6개월판매건수']}}</td> -->
									<td>
										<div>
											<!-- <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset"
												href="javascript:void(0)" @click="resetitem(i)"><i class="ri-pencil-line mr-0"></i></a>													 -->
											<a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
												href="javascript:void(0)" @click="deleteitem(i)"><i class="ri-delete-bin-line mr-0"></i></a>
										</div>
									</td>
								</tr>
							</template>
						</tableList>
					</div> 
					<button type="button" class="btn btn-secondary mr-2 mb-4" @click="resetitems">전체삭제</button> 
					<button type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button>
				</div>
			</card>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
export default {
	name:'rank',
	data(){
		return{
			related:false,

			loadingtext:'소싱상품 수집 중',
			loadingrate:'',

			showspinner:false,
			showlist:false,

			waitresult:false,
			targetKeyword:'',
			fullsearch:false,
			addlines: 10,
			rowlines:[],
			auth:'',


			title:"네이버 해외직구 상품소싱",
			desc1:"키워드 별 스마트스토어에서 최근 판매된 해외직구상품을 수집합니다.",
			desc2:"키워드를 분석할 때마다 아래로 수집된 결과가 쌓입니다.",
			desc3:"",
		}
	},
    created(){
        document.addEventListener.call(window, "message", async(event) => {
			if(event.data.res && event.data.res === 'login'){
				alert('쿠팡wing에 로그인 해주세요')
				this.showspinner = false;
				this.showlist = false;
				return
			}
			if(event.data.res && event.data.res === 'keywordanalysisstatus'){
                console.log(event.data.status);
				this.loadingtext = '키워드 별 해외직구 제품 가져오는 중';
				this.loadingrate = '(' + (event.data.status*100).toFixed(0) + "%)";
				this.showspinner = true;
				this.showlist = false;
				// var obj = event.data.result;
				// this.rowlines[obj.index].rank = obj.rank;
				// this.rowlines[obj.index].adqty = obj.adqty;
				// this.rowlines[obj.index].adplace = obj.adplace;
				// this.rowlines[obj.index].productqty = obj.productqty;
				// this.rowlines[obj.index].rocketqty = obj.rocketqty;
				// this.rowlines[obj.index].totalrank = obj.totalrank;
				// this.waitresult = false;
				// await this.sleep(10);
				// this.showspinner = false;
				// this.showlist = true;
            }
            if(event.data.res && event.data.res === 'storeparsing'){
				console.log(event.data.storeparsing);
				var storearr = event.data.storeparsing;
				var storear = [];
				storearr.forEach(e => {
					if(e.keywordvalue && e.keywordvalue.find(ele => ele.long > 0) && e.keywordvalue.find(ele => !this.rowlines.find(f => f.id == ele.info.id))){
						e.keywordvalue.filter(ele => ele.long > 0 && !this.rowlines.find(f => f.id === ele.info.id)).forEach(f => {
							if(!storear.find(element => element.id === f.info.id)){
								// f.long = f.info.productDeliveryLeadTimes ? f.info.productDeliveryLeadTimes.reduce((pv,cv)=>{return pv + cv.leadTimeCount},0) : 0;
								storear.push({
									keyword : e.keyword,
									상품링크:f.info.productUrl,
									상품이미지:f.info.productImages[0].url,
									상품명:f.info.name,
									카테고리:f.info.category.wholeCategoryName,
									판매가:f.price,
									배송비:f.info.productDeliveryInfo.baseFee,
									// 등록일:f.info.regDate.slice(0,10),
									리뷰수:f.info.reviewAmount.totalReviewCount,
									평점:f.info.reviewAmount.averageReviewScore,
									월판매수:f.long,		
									월매출:f.long*f.price,
									'7일판매건수':f.short,
									id : f.info.id
								})
							}
						})
					}
				})
				this.loadingtext = '데이터 계산 중';
				this.loadingrate = "";
				this.showspinner = true;
				this.showlist = false;
				
				this.makerowlines(storear);


				// var obj = event.data.result;
				// this.rowlines[obj.index].rank = obj.rank;
				// this.rowlines[obj.index].adqty = obj.adqty;
				// this.rowlines[obj.index].adplace = obj.adplace;
				// this.rowlines[obj.index].productqty = obj.productqty;
				// this.rowlines[obj.index].rocketqty = obj.rocketqty;
				// this.rowlines[obj.index].totalrank = obj.totalrank;
				// this.waitresult = false;
				// await this.sleep(10);
				// this.showspinner = false;
				// this.showlist = true;
            }
            if(event.data.res && event.data.res === 'relatedkeywords'){
                console.log(event.data.relatedkeywords)
				var arr = event.data.relatedkeywords;
				if(this.related){
					var ar = [this.targetKeyword]
				}
				console.log(arr)
				arr.forEach(ele => {
					if(!ar.includes(ele)){
						ar.push(ele)
					}
				})
				var keywords = [];
				console.log(ar)
				ar.forEach(ele => {
					if(!this.rowlines.find(element => element.targetKeyword == ele)){
						keywords.push(ele);
					}
				})
				console.log(keywords)
				// this.rowlines = [];
				
					this.importresults(keywords)
            }		
			
			
        });
    },	
	mounted(){
		this.startfocus();
		this.getAuthNo();
	},
	methods: {
		async getAuthNo(){
			axios.post('/api/getAuthNo')
			.then(res => {
				this.auth = res.data.auth;
			}).catch(console.log)
		},
		resetitems(){
			this.rowlines = [];
		},
		searchenter(event){
			if(event.keyCode === 13){
				this.search();
			}
		},
		startfocus(){
			this.$refs.cursor.focus();
		},
		middlelevel(arr) {
			arr.sort((a,b) => a-b);
			var val = parseInt(arr.length/2);
			return arr[val];
		},
		async makerowlines(storear){
			console.log(storear)
			this.rowlines = this.rowlines.concat(storear)
			setTimeout(()=>{
				// this.loadingtext = '키워드 별 CPC단가 분석 중';
				this.loadingrate = '';
				this.showspinner = false;
				this.showlist = true;
			},1000)


		},
		async sleep(ms) {
			return new Promise((r) => setTimeout(r, ms));
		},
		async importresults(arr){
			await this.sleep(1000);
			this.loadingtext = '키워드 별 스마트스토어 해외직구 상품 수집 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
			window.postMessage({greeting: "storeparsing",data:arr,auth:this.auth},"*",)
			// // this.waitresult = false;
			// for(var i=0;i<this.rowlines.length;i++){
			// 	var e = this.rowlines[i];
			// 	await this.sleep(1000)
			// 	if(e.targetKeyword || e.productName || e.itemId || e.productId || e.vendorItemId){
			// 		e.showspinner = true;
			// 		e.rank = [];
			// 		e.adqty = '';
			// 		e.adplace = '';
			// 		e.productqty = '';
			// 		e.rocketqty = '';
			// 		e.totalrank = [];
			// 		var data = {
			// 			targetKeyword : e.targetKeyword,
			// 			productName : e.productName,
			// 			itemId : e.itemId,
			// 			productId : e.productId,
			// 			vendorItemId : e.vendorItemId,
			// 			index : i,
			// 			fullsearch : false,
			// 		}
			// 		// console.log(data)
			// 		window.postMessage({greeting: "coupangrank",data:data},"*",)
			// 		// this.waitresult = true;
			// 	}
			// }
			// // this.waitresult = false;
		},
		changelines(){
			this.rowlines = [];
			for(var i=0;i<this.addlines;i++){
				this.rowlines.push({targetKeyword:'',productName:'',itemId:'',productId:'',vendorItemId:'',rank:[],adqty:'',adplace:'',productqty:'',rocketqty:'',showspinner:false,totalrank:[]})
			}
		},
		resetitem(i){
			this.rowlines[i].targetKeyword = '';
			this.rowlines[i].productName = '';
			this.rowlines[i].itemId = '';
			this.rowlines[i].productId = '';
			this.rowlines[i].rank = [];
			this.rowlines[i].adqty = '';
			this.rowlines[i].adplace = '';
			this.rowlines[i].productqty = '';
			this.rowlines[i].rocketqty = '';
			this.rowlines[i].showspinner = false;
			this.rowlines[i].totalrank = [];
		},
		deleteitem(i){
			this.rowlines = this.rowlines.slice(0,i).concat(this.rowlines.slice(i+1))
			if(this.rowlines.length == 0){
				this.showlist = false;
			}
			this.addlines--
		},	
		search(){
			if(!this.targetKeyword){
				alert('분석할 키워드를 입력해 주세요')
				return
			}
			if(!this.related){
				this.importresults([this.targetKeyword])
				return 
			}
			window.postMessage({greeting: "getrelatedkeywords",data:this.targetKeyword},"*",)

			this.loadingtext = '연관검색어 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
		},
		searchresult(){
			this.saleslists = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			var 이전재고 = '';
			var t = {};
			var su = {};
			var 입고수량 = 0;
			if(this.$store.state.sales.length > 0){
				this.$store.state.sales.sort(function(a,b){
					if (a.date < b.date){
						return -1;
					}
					if (a.date > b.date){
						return 1;
					}
					return 0;
				})	
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				this.$store.state.productdb.forEach(e => {
					if(this.$store.state.sales.filter(ele => ele.판매세부내역.find(element => element.sk == e["SKU ID"])).length == this.$store.state.sales.length){
						obj = {
							썸네일 : e.썸네일,
							판매상품명 : e.판매상품명,
							SKUID : e["SKU ID"],
							바코드 : e.바코드,
							발주가능상태 : e.발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : e.판매링크,
							판매세부내역 : []
						}
						if(this.$store.state.sales.length > 1){
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								//console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									//console.log(su)
									if(su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).length > 0){
										입고수량 = su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).reduce((pv,rv) => {return pv + parseInt(rv.수량)},0)
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								if(이전재고 == ''){
									이전재고 = t.s ? t.s : 0;
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s+입고수량 ? t.s+입고수량 : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								이전재고 = d.재고 ? d.재고 : 0;
							})
							var salesqty = 0
							for (var j=1;j<obj.판매세부내역.length;j++){
								salesqty = obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 ? obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 : 0;
								obj.판매세부내역[j].판매 = salesqty ? salesqty : 0;
								obj.판매 += salesqty ? salesqty : 0;
								obj.판매세부내역[j].판매매출 = salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매매출 += salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매세부내역[j].공급매출 = salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								obj.공급매출 += salesqty*e.매입가 ? salesqty*e.매입가 : 0;
							}
						} else {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								// console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									// console.log(su)
									if(su.importlist.find(element => element.sku번호.toString() == t.sk.toString())){
										입고수량 = parseInt(su.importlist.find(element => element.sku번호.toString() == t.sk.toString()).수량);
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s ? t.s : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.판매 = 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								obj.판매매출 = 0;
								obj.공급매출 = 0;
								setTimeout(() => {이전재고 = d.재고 ? d.재고 : 0},1)
							})
						}
						this.saleslists.push(this.deepClone(obj))
						this.saleslistsinit.push(this.deepClone(obj))
					}
				})
				
				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}			
			
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		}, 		
		todate(date){
			var today = new Date(date); 
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },		
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},   
		download() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			this.rowlines.forEach(e => {
				arr.push({
					키워드 : e.targetKeyword,
					쿠팡1p윙제품수 : e.wing1p,
					쿠팡1p로켓제품수 : e.rocket1p,
					쿠팡1p그로스제품수 : e.growth1p,
					쿠팡광고비율 : e.adrate,
					쿠팡광고상품수 : e.adqty,
					쿠팡광고구좌수: e.adplace,
					쿠팡광고CPC단가_추천: e.cpc,
					쿠팡광고CPC단가_최대: e.cpchigh,
					쿠팡광고CPC단가_최저: e.cpclow,
					쿠팡로켓비율: e.rocketrate,
					쿠팡로켓상품수: e.rocketqty,
					쿠팡전체상품수: e.totalqty,
					쿠팡1p리뷰수_평균: e.review1p,
					쿠팡1p리뷰수_최대: e.review1phigh,
					쿠팡1p리뷰수_최저: e.review1plow,
					쿠팡1p리뷰수_중간: e.review1pmiddle,
					네이버1p월판매수량_LT_평균: e.n1psalesqty,
					네이버1p월판매수량_LT_최대: e.n1psalesqtyhigh,
					네이버1p월판매수량_LT_최저: e.n1psalesqtylow,
					네이버1p월판매수량_LT_중간: e.n1psalesqtymiddle,
					네이버1p월매출_LT_평균: e.n1psales,
					네이버1p월매출_LT_최대: e.n1psaleshigh,
					네이버1p월매출_LT_최저: e.n1psaleslow,
					네이버1p월매출_LT_중간: e.n1psalesmiddle,	
					네이버1p월판매수량_ST_평균: e.n1psalesqtyst,
					네이버1p월판매수량_ST_최대: e.n1psalesqtyhighst,
					네이버1p월판매수량_ST_최저: e.n1psalesqtylowst,
					네이버1p월판매수량_ST_중간: e.n1psalesqtymiddlest,
					네이버1p월매출_ST_평균: e.n1psalesst,
					네이버1p월매출_ST_최대: e.n1psaleshighst,
					네이버1p월매출_ST_최저: e.n1psaleslowst,
					네이버1p월매출_ST_중간: e.n1psalesmiddlest,						
				})
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '키워드분석')
			XLSX.writeFile(workBook, '키워드분석' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>